type ApplicationArea = {
    title: string;
    list: string[];
};

type RawMaterial = {
    text: string;
    description?: string;
};

export type TpsInfo = {
    slug: string;
    image: string;
    station: string;
    region: string;
    city: string;
    address: string;
    thermalPower: number; // Гкал/ч
    electricPower: number; // МВт
    typeOfFuel: string;
    secondaryRawMaterials: number; // общий Объем втор. сырья, тонн
    zolaUnosa: number; // зола-уноса, тонн
    fuelSlag?: number; // топливный шлак, тонн
    rawMaterials: RawMaterial[]; // Доступное к реализации сырье на станции
    shipment?: string[];
    areasOfApplication: ApplicationArea[];
    systemOfApplication?: ApplicationArea[];
    systemImageSrc?: string;
    video?: {
        popupName: string;
        name: string;
        duration: string;
    };
};

const zshmAreasOfApplication = [
    'Рекультивация нарушенных земель (технический этап)',
    'Вертикальная планировка территорий',
    'Строительные работы по отсыпке котлованов и выемок',
    'Сооружение нижней части насыпей земляного полотна',
    'Производство строительных материалов',
    'Формирование промежуточного изолирующего слоя на полигонах ТКО и промышленных отходов',
];

const zolaUnosaAreasOfApplication = [
    'Производство бетона',
    'Производство сухих строительных смесей',
    'Производство цемента',
    'Использование в качестве активной минеральной добавки',
    'Использование в качестве мелкого заполнителя',
    'Стабилизация грунтов земляного полотна',
];

const complexMineralBinderAreasOfApplication = [
    'Стабилизация и укрепление грунтов',
    'Холодная регенерация дорожных одежд',
];

const slagSandAreasOfApplication = [
    'Устройство подстилающих слоев основания',
    'Заполнитель для бетонов и асфальтобетонов',
    'Противогололедный материал',
];

const slagRubbleAreasOfApplication = ['Устройство основания', 'Заполнитель для бетонов и асфальтобетонов'];

export const TpsData: TpsInfo[] = [
    {
        station: 'Барнаульская ТЭЦ-2',
        slug: 'barnaulskaya-tets-2',
        image: 'barnaulskaya-tets-2',
        region: 'Алтайский край',
        city: 'Барнаул',
        address: 'Барнаул, ул. Бриллиантовая, 2',
        thermalPower: 1087,
        electricPower: 275,
        typeOfFuel: 'Каменный уголь марки Д',
        secondaryRawMaterials: 141000,
        zolaUnosa: 133950,
        fuelSlag: 7050,
        rawMaterials: [
            {
                text: 'Золошлаковые материалы',
            },
        ],
        shipment: ['Автотранспорт'],
        areasOfApplication: [
            {
                title: 'Золошлаковые материалы',
                list: zshmAreasOfApplication,
            },
        ],
    },
    {
        station: 'Барнаульская ТЭЦ-3',
        slug: 'barnaulskaya-tets-3',
        image: 'barnaulskaya-tets-3',
        region: 'Алтайский край',
        city: 'Барнаул',
        address: 'Барнаул, ул. Трактовая, 7',
        thermalPower: 1450,
        electricPower: 445,
        typeOfFuel: 'Бурый уголь марки Б2Р',
        secondaryRawMaterials: 107000,
        zolaUnosa: 69550,
        fuelSlag: 37450,
        rawMaterials: [
            {
                text: 'Зола-уноса',
            },
            {
                text: 'Золошлаковые материалы',
            },
        ],
        shipment: ['Автотранспорт'],
        areasOfApplication: [
            {
                title: 'Зола-уноса',
                list: zolaUnosaAreasOfApplication,
            },
            {
                title: 'Золошлаковые материалы',
                list: zshmAreasOfApplication,
            },
        ],
    },
    {
        station: 'Бийская ТЭЦ',
        slug: 'biyskaya-tets',
        image: 'biyskaya-tets',
        region: 'Алтайский край',
        city: 'Бийск',
        address: 'Бийск, ул. Михаила Кутузова, 116',
        thermalPower: 1115,
        electricPower: 510,
        typeOfFuel: 'Каменный уголь марки Д',
        secondaryRawMaterials: 212000,
        zolaUnosa: 201400,
        fuelSlag: 10600,
        rawMaterials: [
            {
                text: 'Золошлаковые материалы',
            },
        ],
        shipment: ['Автотранспорт'],
        areasOfApplication: [
            {
                title: 'Золошлаковые материалы',
                list: zshmAreasOfApplication,
            },
        ],
    },
    {
        station: 'Беловская ГРЭС',
        slug: 'belovskaya-gres',
        image: 'belovskaya-gres',
        region: 'Кемеровская область',
        city: 'Белово',
        address: 'Белово, пос. Инской, мкрн Технологический, 5, а/я 138',
        thermalPower: 229,
        electricPower: 1260,
        typeOfFuel: 'Каменный уголь марки Д, Г и их производные',
        secondaryRawMaterials: 582000,
        zolaUnosa: 494700,
        fuelSlag: 87300,
        rawMaterials: [
            {
                text: 'Золошлаковые материалы',
            },
        ],
        shipment: ['Автотранспорт'],
        areasOfApplication: [
            {
                title: 'Золошлаковые материалы',
                list: zshmAreasOfApplication,
            },
        ],
    },
    {
        station: 'Кемеровская ГРЭС',
        slug: 'kemerovskaya-gres',
        image: 'kemerovskaya-gres',
        region: 'Кемеровская область',
        city: 'Кемерово',
        address: 'Кемерово, ул. Станционная, 17 к2',
        thermalPower: 1540,
        electricPower: 485,
        typeOfFuel: 'Каменный уголь марки Д, Г и их производные',
        secondaryRawMaterials: 128000,
        zolaUnosa: 108800,
        fuelSlag: 19200,
        rawMaterials: [
            {
                text: 'Зола-уноса',
                description: 'с 2025 года',
            },
            {
                text: 'Золошлаковые материалы',
            },
        ],
        shipment: ['Автотранспорт'],
        areasOfApplication: [
            {
                title: 'Зола-уноса',
                list: [
                    'Производство бетона',
                    'Производство сухих строительных смесей',
                    'Производство цемента',
                    'Использование в качестве активной минеральной добавки',
                    'Использование в качестве мелкого заполнителя',
                ],
            },
            {
                title: 'Золошлаковые материалы',
                list: zshmAreasOfApplication,
            },
        ],
        systemOfApplication: [
            {
                title: 'Этап 1 - 2024 г.',
                list: ['Возможность отгрузки до 53 тыс. тонн золы в год предприятиям Кузбасса и Сибири.'],
            },
            {
                title: 'Этап 2 - 2028 г.',
                list: ['Увеличение отгрузок до 97 тыс. тонн в год.'],
            },
            {
                title: 'Этап 3 - 2035 г.',
                list: ['Строительство крупнотоннажных силосов с возможностью 100% отгрузки золы-уноса.'],
            },
        ],
        systemImageSrc: '/static/img/svg/system-schema.svg',
    },
    {
        station: 'Кемеровская ТЭЦ',
        slug: 'kemerovskaya-tets',
        image: 'kemerovskaya-tets',
        region: 'Кемеровская область',
        city: 'Кемерово',
        address: 'Кемерово, ул. Кировская, 1',
        thermalPower: 749,
        electricPower: 80,
        typeOfFuel: 'Каменный уголь марки Д, Г и их производные',
        secondaryRawMaterials: 35000,
        zolaUnosa: 33250,
        fuelSlag: 1750,
        rawMaterials: [
            {
                text: 'Золошлаковые материалы',
            },
        ],
        shipment: ['Автотранспорт'],
        areasOfApplication: [
            {
                title: 'Золошлаковые материалы',
                list: zshmAreasOfApplication,
            },
        ],
    },
    {
        station: 'Ново-Кемеровская ТЭЦ',
        slug: 'novo-kemerovskaya-tets',
        image: 'novo-kemerovskaya-tets',
        region: 'Кемеровская область',
        city: 'Кемерово',
        address: 'Кемерово, ул. Грузовая, 1Б',
        thermalPower: 1449,
        electricPower: 580,
        typeOfFuel: 'Каменный уголь марки Д, Г и их производные',
        secondaryRawMaterials: 193000,
        zolaUnosa: 164050,
        fuelSlag: 28950,
        rawMaterials: [
            {
                text: 'Золошлаковые материалы',
            },
        ],
        shipment: ['Автотранспорт'],
        areasOfApplication: [
            {
                title: 'Золошлаковые материалы',
                list: zshmAreasOfApplication,
            },
        ],
    },
    {
        station: 'Томь-Усинская ГРЭС',
        slug: 'tom-usinskaya-gres',
        image: 'tom-usinskaya-gres',
        region: 'Кемеровская область',
        city: 'Мыски',
        address: 'Мыски, ул. Ленина, 50',
        thermalPower: 194,
        electricPower: 1345,
        typeOfFuel: 'Каменный уголь марки Д, Г и их производные',
        secondaryRawMaterials: 406000,
        zolaUnosa: 345100,
        fuelSlag: 60900,
        rawMaterials: [
            {
                text: 'Золошлаковые материалы',
            },
            {
                text: 'Шлаковый песок',
            },
        ],
        shipment: ['Автотранспорт'],
        areasOfApplication: [
            {
                title: 'Золошлаковые материалы',
                list: zshmAreasOfApplication,
            },
            {
                title: 'Шлаковый песок',
                list: slagSandAreasOfApplication,
            },
        ],
    },
    {
        station: 'Канская ТЭЦ',
        slug: 'kanskaya-tets',
        image: 'kanskaya-tets',
        region: 'Красноярский край',
        city: 'Канск',
        address: 'Канск, ул. 40 лет Октября, 58',
        thermalPower: 325,
        electricPower: 24,
        typeOfFuel: 'Бурый уголь',
        secondaryRawMaterials: 12000,
        zolaUnosa: 11400,
        fuelSlag: 600,
        rawMaterials: [
            {
                text: 'Золошлаковые материалы',
            },
        ],
        shipment: ['Автотранспорт'],
        areasOfApplication: [
            {
                title: 'Золошлаковые материалы',
                list: zshmAreasOfApplication,
            },
        ],
    },
    {
        station: 'Красноярская ГРЭС-2',
        slug: 'krasnoyarskaya-gres-2',
        image: 'krasnoyarskaya-gres-2',
        region: 'Красноярский край',
        city: 'Зеленогорск',
        address: 'Зеленогорск, ул. 1-я Промышленная, 2',
        thermalPower: 976,
        electricPower: 1260,
        typeOfFuel: 'Бурый уголь',
        secondaryRawMaterials: 250000,
        zolaUnosa: 212500,
        fuelSlag: 37500,
        rawMaterials: [
            {
                text: 'Зола-уноса',
            },
            {
                text: 'Золошлаковые материалы',
            },
        ],
        shipment: ['Железнодорожный транспорт'],
        areasOfApplication: [
            {
                title: 'Зола-уноса',
                list: zolaUnosaAreasOfApplication,
            },
            {
                title: 'Золошлаковые материалы',
                list: zshmAreasOfApplication,
            },
        ],
    },
    {
        station: 'Красноярская ТЭЦ-1',
        slug: 'krasnoyarskaya-tets-1',
        image: 'krasnoyarskaya-tets-1',
        region: 'Красноярский край',
        city: 'Красноярск',
        address: 'Красноярск, ул. Фестивальная, 2',
        thermalPower: 1677,
        electricPower: 486,
        typeOfFuel: 'Бурый уголь',
        secondaryRawMaterials: 124000,
        zolaUnosa: 93000,
        fuelSlag: 31000,
        rawMaterials: [
            {
                text: 'Зола-уноса',
            },
            {
                text: 'Золошлаковые материалы',
            },
        ],
        shipment: ['Железнодорожный транспорт'],
        areasOfApplication: [
            {
                title: 'Зола-уноса',
                list: zolaUnosaAreasOfApplication,
            },
            {
                title: 'Золошлаковые материалы',
                list: zshmAreasOfApplication,
            },
        ],
    },
    {
        station: 'Красноярская ТЭЦ-2',
        slug: 'krasnoyarskaya-tets-2',
        image: 'krasnoyarskaya-tets-2',
        region: 'Красноярский край',
        city: 'Красноярск',
        address: 'Красноярск, ул. Лесопильщиков, 156',
        thermalPower: 1405,
        electricPower: 465,
        typeOfFuel: 'Бурый уголь',
        secondaryRawMaterials: 106000,
        zolaUnosa: 90100,
        fuelSlag: 15900,
        rawMaterials: [
            {
                text: 'Зола-уноса',
            },
            {
                text: 'Золошлаковые материалы',
            },
        ],
        shipment: ['Автотранспорт', 'Железнодорожный транспорт'],
        areasOfApplication: [
            {
                title: 'Зола-уноса',
                list: zolaUnosaAreasOfApplication,
            },
            {
                title: 'Золошлаковые материалы',
                list: zshmAreasOfApplication,
            },
        ],
    },
    {
        station: 'Красноярская ТЭЦ-3',
        slug: 'krasnoyarskaya-tets-3',
        image: 'krasnoyarskaya-tets-3',
        region: 'Красноярский край',
        city: 'Красноярск',
        address: 'Красноярск, ул. Пограничников, 5',
        thermalPower: 582,
        electricPower: 208,
        typeOfFuel: 'Бурый уголь',
        secondaryRawMaterials: 59000,
        zolaUnosa: 56050,
        fuelSlag: 2950,
        rawMaterials: [
            {
                text: 'Зола-уноса',
            },
            {
                text: 'Золошлаковые материалы',
            },
        ],
        shipment: ['Автотранспорт'],
        areasOfApplication: [
            {
                title: 'Зола-уноса',
                list: zolaUnosaAreasOfApplication,
            },
            {
                title: 'Золошлаковые материалы',
                list: zshmAreasOfApplication,
            },
        ],
    },
    {
        station: 'Минусинская ТЭЦ',
        slug: 'minusinskaya-tets',
        image: 'minusinskaya-tets',
        region: 'Красноярский край',
        city: 'Минусинск',
        address: 'Минусинск, а/я 531 (промплощадка ТЭЦ)',
        thermalPower: 330,
        electricPower: 85,
        typeOfFuel: 'Бурый уголь',
        secondaryRawMaterials: 20000,
        zolaUnosa: 13000,
        fuelSlag: 7000,
        rawMaterials: [
            {
                text: 'Зола-уноса',
            },
            {
                text: 'Золошлаковые материалы',
            },
        ],
        shipment: ['Автотранспорт'],
        areasOfApplication: [
            {
                title: 'Зола-уноса',
                list: zolaUnosaAreasOfApplication,
            },
            {
                title: 'Золошлаковые материалы',
                list: zshmAreasOfApplication,
            },
        ],
    },
    {
        station: 'Назаровская ГРЭС',
        slug: 'nazarovskaya-gres',
        image: 'nazarovskaya-gres',
        region: 'Красноярский край',
        city: 'Назарово',
        address: 'Назарово, а/я 42',
        thermalPower: 775,
        electricPower: 1313,
        typeOfFuel: 'Бурый уголь',
        secondaryRawMaterials: 191000,
        zolaUnosa: 162350,
        fuelSlag: 28650,
        rawMaterials: [
            {
                text: 'Золошлаковые материалы',
            },
        ],
        shipment: ['Автотранспорт'],
        areasOfApplication: [
            {
                title: 'Золошлаковые материалы',
                list: zshmAreasOfApplication,
            },
        ],
    },
    {
        station: 'Новосибирская ТЭЦ-2',
        slug: 'novosibirskaya-tets-2',
        image: 'novosibirskaya-tets-2',
        region: 'Новосибирская область',
        city: 'Новосибирск',
        address: 'Новосибирск, ул. Станционная, 4',
        thermalPower: 920,
        electricPower: 345,
        typeOfFuel: 'Каменный уголь',
        secondaryRawMaterials: 136000,
        zolaUnosa: 129200,
        fuelSlag: 6800,
        rawMaterials: [
            {
                text: 'Золошлаковые материалы',
            },
        ],
        shipment: ['Автотранспорт'],
        areasOfApplication: [
            {
                title: 'Золошлаковые материалы',
                list: zshmAreasOfApplication,
            },
        ],
    },
    {
        station: 'Новосибирская ТЭЦ-3',
        slug: 'novosibirskaya-tets-3',
        image: 'novosibirskaya-tets-3',
        region: 'Новосибирская область',
        city: 'Новосибирск',
        address: 'Новосибирск, ул. Большая, 310',
        thermalPower: 945,
        electricPower: 497,
        typeOfFuel: 'Бурый уголь',
        secondaryRawMaterials: 92000,
        zolaUnosa: 59800,
        fuelSlag: 32200,
        rawMaterials: [
            {
                text: 'Зола-уноса',
            },
            {
                text: 'Золошлаковые материалы',
            },
        ],
        shipment: ['Автотранспорт'],
        areasOfApplication: [
            {
                title: 'Зола-уноса',
                list: zolaUnosaAreasOfApplication,
            },
            {
                title: 'Золошлаковые материалы',
                list: zshmAreasOfApplication,
            },
        ],
        systemOfApplication: [
            {
                title: 'Февраль 2024 г.',
                list: ['Введение в эксплуатацию.'],
            },
        ],
        systemImageSrc: '/static/img/svg/system-schema-n3.svg',
        video: {
            popupName: 'video-player-station-1-map',
            name: 'Отгрузка золы',
            duration: '01:12',
        },
    },
    {
        station: 'Новосибирская ТЭЦ-5',
        slug: 'novosibirskaya-tets-5',
        image: 'novosibirskaya-tets-5',
        region: 'Новосибирская область',
        city: 'Новосибирск',
        address: 'Новосибирск, ул. Выборная, 201',
        thermalPower: 2730,
        electricPower: 1200,
        typeOfFuel: 'Бурый уголь',
        secondaryRawMaterials: 239000,
        zolaUnosa: 227050,
        fuelSlag: 11950,
        rawMaterials: [
            {
                text: 'Зола-уноса',
            },
            {
                text: 'Золошлаковые материалы',
            },
        ],
        shipment: ['Автотранспорт'],
        areasOfApplication: [
            {
                title: 'Зола-уноса',
                list: zolaUnosaAreasOfApplication,
            },
            {
                title: 'Золошлаковые материалы',
                list: zshmAreasOfApplication,
            },
        ],
        systemOfApplication: [
            {
                title: 'Этап 1 - апрель 2026 г.',
                list: ['Возможность отгрузки до 73 тыс. тонн золы в год.'],
            },
            {
                title: 'Этап 2-3 - октябрь 2026 г.',
                list: ['Увеличение отгрузок до 217 тыс. тонн в год.'],
            },
        ],
        systemImageSrc: '/static/img/svg/system-schema-n5.svg',
    },
    {
        station: 'Абаканская ТЭЦ',
        slug: 'abakanskaya-tets',
        image: 'abakanskaya-tets',
        region: 'Республика Хакасия',
        city: 'Абакан',
        address: 'Абакан, а/я 1274, Промышленная площадка, Ташебинский промузел "Абаканская ТЭЦ"',
        thermalPower: 700,
        electricPower: 406,
        typeOfFuel: 'Бурый уголь',
        secondaryRawMaterials: 94000,
        zolaUnosa: 75200,
        fuelSlag: 18800,
        rawMaterials: [
            {
                text: 'Зола-уноса',
            },
            {
                text: 'Золошлаковые материалы',
            },
        ],
        shipment: ['Автотранспорт'],
        areasOfApplication: [
            {
                title: 'Зола-уноса',
                list: zolaUnosaAreasOfApplication,
            },
            {
                title: 'Золошлаковые материалы',
                list: zshmAreasOfApplication,
            },
        ],
    },
    {
        station: 'Рефтинская ГРЭС',
        slug: 'reftinskaya-gres',
        image: 'reftinskaya-gres',
        region: 'Свердловская область',
        city: 'Рефтинский',
        address: 'г.о. Рефтинский',
        thermalPower: 350,
        electricPower: 3800,
        typeOfFuel: 'Каменный уголь',
        secondaryRawMaterials: 4864000,
        zolaUnosa: 4864000,
        rawMaterials: [
            {
                text: 'Зола-уноса',
            },
        ],
        shipment: ['Автотранспорт', 'Железнодорожный транспорт'],
        areasOfApplication: [
            {
                title: 'Зола-уноса',
                list: [
                    'Производство бетона',
                    'Производство сухих строительных смесей',
                    'Производство цемента',
                    'Использование в качестве активной минеральной добавки',
                    'Использование в качестве мелкого заполнителя',
                ],
            },
        ],
    },
    {
        station: 'Приморская ГРЭС',
        slug: 'primorskaya-gres',
        image: 'primorskaya-gres',
        region: 'Приморский край',
        city: 'Лучегорск',
        address: 'п.г.т. Лучегорск',
        thermalPower: 237,
        electricPower: 1467,
        typeOfFuel: 'Бурый уголь',
        secondaryRawMaterials: 1426000,
        zolaUnosa: 1354700,
        fuelSlag: 71300,
        rawMaterials: [
            {
                text: 'Зола-уноса',
                description: 'после 2025 года',
            },
        ],
        areasOfApplication: [
            {
                title: 'Зола-уноса',
                list: zolaUnosaAreasOfApplication,
            },
        ],
    },
];
